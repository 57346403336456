import React from "react";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Resume from "./Components/Resume";
import Portfolio from "./Components/Portfolio";
import initFontAwesome from "./initFontAwesome";

initFontAwesome();

export default function Home() {

  const resumeData = {
    "main": {
      "name": "Al Elizalde",
      "occupation": "Software Engineer",
      "description": "Senior Backend Software Engineer with experience on distributed systems",
      "image": "profilepic.jpeg",
      "bio": "Backend Distributed Systems Software Engineer. Stackoverflow, Wikipedia and Apache Cassandra contributor. Open source software enthusiast and robotics maker.",
      "contactmessage": "Here is where you should write your message to readers to have them get in contact with you.",
      "email": "elizalde.alfonso@gmail.com",
      "phone": "",
      "address": {
        "street": "",
        "city": "San Francisco Bay Area",
        "state": "California",
        "zip": "95133"
      },
      "website": "http://www.alelizalde.com",
      "social": [
        {
          "name": "linkedin",
          "url": "https://www.linkedin.com/in/al-elizalde"
        },
        {
          "name": "instagram",
          "url": "https://www.instagram.com/maceliz"
        },
        {
          "name": "github",
          "url": "http://github.com/alelizalde"
        },
        {
          "name": "mail",
          "url": "mailto:elizalde.alfonso@gmail.com"
        }
      ]
    },
    "resume": {},
    "portfolio": {
      "projects": [
        {
          "title": "easypad.io",
          "category": "Online easy to use Notepad.",
          "image": "easypad.io.png",
          "url": "https://easypad.io"
        },
        {
          "title": "Golang Chat",
          "category": "golang chat using kafka",
          "image": "golang-chat.png",
          "url": "https://github.com/maceliz/chat-app/blob/master/README.md"
        },
        {
          "title": "Draft.js editor basics",
          "category": "Learn how to create an editor with draft.js for react",
          "image": "draftjs.png",
          "url": "https://medium.com/@al.elizalde/draft-js-f4a5c32646c7"
        },
        {
          "title": "Multiple Website Server",
          "category": "How to Install Multiple Websites in one Server",
          "image": "multiwebsite.jpg",
          "url": "https://medium.com/@al.elizalde/how-to-install-multiple-websites-in-one-server-707860d55ed9"
        },
        {
          "title": "CNN Backpropagation",
          "category": "Convolutional Neural Network (CNN) Backpropagation",
          "image": "cnn.png",
          "url": "/cnn"
        }
      ]
    }
  };
  
    return (
      <div className="App">
        <Header data={resumeData.main} />
        <About data={resumeData.main} />
        <Resume data={resumeData.resume} />
        <Portfolio data={resumeData.portfolio} />
        <Footer data={resumeData.main} />
      </div>
    );
}
