import React from 'react';
import './skills.css'

export default function Skills() {
  return (
    <div>
        <table>
          <tbody>
              <tr>
                <th>Languages</th>
                <td>Java, Shell script, scala, python, HTML, javascript, kotlin, SQL, CQL, golang</td>
              </tr><tr>
                <th>NoSQL Databases</th>
                <td>Apache Cassandra, MongoDB</td>
              </tr><tr>
              </tr><tr>
                <th>Full-text search engine</th>
                <td>Elasticsearch</td>
              </tr><tr>
                <th>RDBMS</th>
                <td>MySQL, Informix, DB2, MS SQL Server</td>
              </tr><tr>
                <th>Graph databases</th>
                <td>Titan, Neo4J</td>
              </tr><tr>
                <th>In-memory databases</th>
                <td>Redis, Memcached</td>
              </tr><tr>
                <th>Data distributed computing and storage</th>
                <td>Hadoop</td>
              </tr><tr>
                <th>Data Analytics / Data ETL</th>
                <td>Apache Spark, Pig, Flume, sqoop, Solr, hive</td>
              </tr><tr>
                <th>Data Monitoring and visualization</th>
                <td>Kibana, Logstash, Graylog, Tableau, Grafana</td>
              </tr><tr>
                <th>Distributed streaming platform</th>
                <td>Apache Kafka, Zookeeper, logstash, filebeat, SOA & REST design, NoSQL Data</td>
              </tr><tr>
                <th>Other technologies</th>
                <td>modeling, cloud architecture, Unix/Linux, MS Azure, OpenStack, rabbitmq, React, nodejs, Play framework</td>
              </tr>
          </tbody>
        </table>
    </div>
  )
}
