import React, { useEffect, useState } from "react";
import "./cnn.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Link } from "react-router-dom";

export default function CNN() {
  const [y, sety] = useState(1);
  const [aL, setaL] = useState(0.1);
  const [aLminus1, setaLminus1] = useState(0.1);
  const [wL, setwL] = useState(0.1);
  const [bL, setbL] = useState(0.1);
  const [zL, setzL] = useState(0.1);
  const [C0, setC0] = useState(0.1);
  const [derivativezLωL, setDerivativezLωL] = useState(0.1);
  const [derivativeaLzL, setDerivativeaLzL] = useState(0.1);
  const [derivativeC0aL, setDerivativeC0aL] = useState(0.1);
  const [derivativeC0wL, setDerivativeC0wL] = useState(0.1);

  useEffect(() => {
    if (
      !isNaN(aLminus1) &&
      !isNaN(y) &&
      !isNaN(wL) &&
      !isNaN(bL) &&
      !isNaN(zL) &&
      !isNaN(aL) &&
      !isNaN(derivativeC0aL) &&
      !isNaN(derivativeaLzL) &&
      !isNaN(derivativezLωL)
    ) {
      setC0(Math.pow(aL - y, 2));
      setaL(Math.max(0, zL)?.toFixed(2));
      setDerivativeC0aL(2 * (aL - y));
      setDerivativeaLzL((zL - (wL * aLminus1 + bL)).toFixed(6));
      setzL(wL * aLminus1 + bL);
      setDerivativezLωL(aLminus1);
      setDerivativeC0wL(
        (derivativezLωL * derivativeaLzL * derivativeC0aL).toFixed(6)
      );
    }
  }, [
    aLminus1,
    y,
    wL,
    bL,
    zL,
    aL,
    derivativeC0aL,
    derivativeaLzL,
    derivativezLωL,
  ]);

  return (
    <div className="centered-screen">
      <div>
        <Link to="/">Back Home</Link>
        <h1>Backpropagation in CNN</h1>
        <p style={{ margin: "30px", width: "90%", color: "#3C3C3C" }}>
          As part of my studies to describe Convolutional Neural Network (CNN)
          Backpropagation at the Rochester Institute of Technology for Image
          Processing class imparted by professor Derek Walvoord.
          <br />
          <br />
          <b>Description:</b> In this project, I explore the process of
          implementing backpropagation in a convolutional neural network (CNN).
          CNNs are widely used in image recognition, object detection, and other
          computer vision tasks. Backpropagation is the standard algorithm used
          for training neural networks, and it allows the network to learn from
          labeled data and improve its performance over time. My goal in this
          project will be to build the most simple CNN architecture and
          implement backpropagation to understand the mathematical
          calculations. Right now I'm only implementing two nodes with one weight
          and my plan is to continue adding more so we can visualize weights
          (and bias pending) impact when values are changed.
        </p>
      </div>
      <div>
        <div className="circle">{aLminus1}</div>
        <div className="connector-wrapper">
          <div className="rectangle">ω={wL}</div>
        </div>
        <div className="circle">{aL}</div>
      </div>

      <div className="centered-table">
        <div className="cell-wrapper">
          <div className="slider">
            ωL
            <Slider
              step={0.01}
              value={wL}
              min={0}
              max={10}
              onChange={(value) => setwL(value)}
            />
          </div>
          <div className="slider">
            aL -1
            <Slider
              step={0.01}
              value={aLminus1}
              min={-10}
              max={10}
              onChange={(value) => setaLminus1(value)}
            />
          </div>
          <div className="slider">
            zL
            <Slider
              disabled={true}
              step={0.01}
              value={zL}
              min={-100}
              max={100}
              onChange={(value) => setzL(value)}
              handleStyle={{ backgroundColor: "gray", borderColor: "gray" }}
              railStyle={{ backgroundColor: "gray", borderColor: "gray" }}
            />
          </div>
          <div className="slider">
            (using ReLu) aL
            <Slider
              disabled={true}
              step={0.01}
              value={aL}
              min={-100}
              max={100}
              onChange={(value) => setaL(value)}
            />
          </div>
        </div>
        <div className="cell-wrapper">
          <div className="centered-table">
            <div className="cell-wrapper">
              desired value for this trainig example = y
            </div>
            <div className="cell-wrapper">
              <input  type="number" value={y} onChange={(event) => sety(event.target.value)} />
            </div>
            <div className="cell-wrapper">(using ReLu) aL</div>
            <div className="cell-wrapper">
              <input type="number" value={aL} disabled={true} />
            </div>
            <div className="cell-wrapper">aL-1</div>
            <div className="cell-wrapper">
              <input  type="number"
                value={aLminus1}
                onChange={(event) => setaLminus1(event.target.value)}
              />
            </div>
            <div className="cell-wrapper">ωL</div>
            <div className="cell-wrapper">
              <input  type="number"
                value={wL}
                onChange={(event) => setwL(event.target.value)}
              />
            </div>
            <div className="cell-wrapper">bL</div>
            <div className="cell-wrapper">
              <input  type="number"
                value={bL}
                onChange={(event) => setbL(event.target.value)}
              />
            </div>
            <div className="cell-wrapper">zL = (ωL * a L-1 + bL)</div>
            <div className="cell-wrapper">
              <input  type="number" value={zL} disabled={true} />
            </div>
            <div className="cell-wrapper">C0 = (aL - y)^2</div>
            <div className="cell-wrapper">
              <input  type="number" value={C0} disabled={true} />
            </div>
            <div className="cell-wrapper">∂C0 / ∂aL = 2(aL - y)</div>
            <div className="cell-wrapper">
              <input  type="number" value={derivativeC0aL} disabled={true} />
            </div>
            <div className="cell-wrapper">∂aL / ∂zL = ReLu(zL)</div>
            <div className="cell-wrapper">
              <input  type="number" value={derivativeaLzL} disabled={true} />
            </div>
            <div className="cell-wrapper">∂zL / ∂wL = a(L - 1)</div>
            <div className="cell-wrapper">
              <input  type="number" value={derivativezLωL} disabled={true} />
            </div>
            <div className="cell-wrapper">
              ∂C0 / ∂wL = (∂zL / ∂wL) * (∂aL / ∂zL) * (∂C0 / ∂aL)
            </div>
            <div className="cell-wrapper">
              <input  type="number" value={derivativeC0wL} disabled={true} />
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
}
