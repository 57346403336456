import React, { Component } from "react";

class About extends Component {
  render() {
    if (this.props.data) {
      var name = this.props.data.name;
      var profilepic = "images/" + this.props.data.image;
      var bio = this.props.data.bio;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var email = this.props.data.email;
    }

    return (
      <section id="about">
        <br /> <br /> <br /> <br />
        <div className="row">
          <div className="three columns">
            <img
              className="profile-pic"
              src={profilepic}
              alt="Al Elizalde Profile Pic"
            />
          </div>
          <div className="nine columns main-col">
            <h2>About Me</h2>
            <p>{bio}</p>
            <br /> <br />
            <div className="row">
              <div className="columns contact-details">
                <h2>Contact Details</h2>
                <p className="address">
                  <span>{name}</span>
                  <br />
                  <span>
                    {city}
                    <br /> {state}, {zip}
                  </span>
                  <br />
                  <span>
                    <a
                      href={"mailto:" + email}
                      style={{ textDecoration: "underline" }}
                    >
                      {email}
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <h2>Milestones</h2>
            <br />
            <h4 style={{ color: "#fff" }}>I have experience with...</h4>
            <p>
              I have managed clusters and horizontally scaled and reduced
              clusters sizes, migrate data from cluster to cluster, I understand
              in detail the internal functionality of Apache and DSE Cassandra.
              I have coded java APIs and REST services to ingest and expose
              data. I have two Cassandra Certifications, Apache Cassandra v3 by
              Datastax academy and Apache Cassandra developer by O’Reilly School
              of Technology. Apache Kafka installation and configuration of
              brokers and zookeeper, producer and consumer implementations with
              automated and manual offset commits, data grouping and data
              modeling and sizing to distribute data across partitions. Deep
              understanding of Hadoop ecosystem, I have implemented Apache
              Spark, MapReduce, hive, pig, sqoop, flume to extract, transform
              and load data from and into HDFS, I also have designed different
              data models on some of them I used hive partitioning and bucketing
              techniques to keep data balanced across the cluster.
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
