import React, { Component } from "react";
import Skills from "./Skills";

class Resume extends Component {
  render() {
    return (
      <section id="resume">
        <div className="row skill">
          <div>
            <h1>
              <span>Skills</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <div className="bars">
              <ul className="skills">
                <Skills />
              </ul>
            </div>
          </div>
        </div>

        <div className="row work">
          <hr
            style={{
              border: "solid #E3E3E3",
              borderWidth: "1px 0 0",
              clear: "both",
              margin: "11px 0 30px",
              height: "0",
            }}
          />
          <div className="three columns header-col">
            <h1>
              <span>Work</span>
            </h1>
          </div>
          <div className="nine columns main-col">
            <div key="Slack">
              <h3>
                <img alt="Slack" width="150px" src="/images/slack.png" />
              </h3>
              <p className="info">
                Senior Software Engineer<span>&bull;</span>{" "}
                <em className="date">July 2020 - Present</em>
              </p>
              <p className="details">
                Helping Slack Software Engineering implementations be innovative
                to make our customers work life simpler, more pleasant, and more
                productive.
              </p>
            </div>
            <br />
            <div key="Walmart1">
              <h3>
                <img alt="Walmart" width="150px" src="/images/walmart.png" />
              </h3>
              <p className="info">
                Staff Software Engineer<span>&bull;</span>{" "}
                <em className="date">Feb 2018 - June 2020</em>
              </p>
              <p className="details">
                Open API team, developing and supporting all Internet Walmart
                exposed API's.
              </p>
            </div>
            <div key="Walmart2">
              <p className="info">
                Senior Software Engineer<span>&bull;</span>{" "}
                <em className="date">Jul 2015 – Feb 2018</em>
              </p>
              <p className="details">
                e-Commerce Integrations team as technology lead, centralizing
                stores price, inventory, product shelf location mapping and
                other item properties for dot com and other internal and
                external consumers implementing for big data working on
                Cassandra, Hadoop, Kafka, Spark, MapReduce and other related
                technologies.
              </p>
            </div>
            <div key="Walmart3">
              <p className="info">
                Senior Software Engineer<span>&bull;</span>{" "}
                <em className="date">Jan 2008 – Jun 2015</em>
              </p>
              <p className="details">
                Finance Application development team implementing Location and
                Supplier new generation master data management system.
              </p>
            </div>
            <div key="Walmart4">
              <p className="info">
                Software Engineer<span>&bull;</span>{" "}
                <em className="date">Jan 2008 – Jun 2015</em>
              </p>
              <p className="details">
                e-Commerce front end support as technology lead helping the
                company to resolve production issues and applying improvements
                on Walmart.com website.
              </p>
            </div>
          </div>
        </div>

        <div className="row education">
          <div className="three columns header-col">
            <h1>
              <span>Education</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">
                <div key="Webster">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="http://www.rit.edu"
                  >
                    <img
                      alt="Rochester Institute of Technology"
                      width="70px"
                      src="/images/rit.png"
                    />
                  </a>
                  <p
                    style={{fontWeight: "bold" }}
                    className="info"
                  >
                    Imaging Science MS<span>&bull;</span>
                    <em className="date">In Progress</em>
                  </p>
                </div>
              </div>
            </div>
            <br />
            <div className="row item">
              <div className="twelve columns">
                <div key="Webster">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="http://www.webster.edu"
                  >
                    <img
                      alt="Webster University"
                      width="70px"
                      src="/images/webster-university.png"
                    />
                  </a>
                  <p
                             style={{fontWeight: "bold" }}
                    className="info"
                  >
                    Master of Business Administration<span>&bull;</span>
                    <em className="date">2015</em>
                  </p>
                </div>
              </div>
            </div>
            <br />
            <div className="row item">
              <div className="twelve columns">
                <div key="unitec">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.unitec.mx"
                  >
                    <img
                      alt="Technological University of México"
                      width="70px"
                      src="/images/unitec.png"
                    />
                  </a>
                  <p
                              style={{fontWeight: "bold" }}
                    className="info"
                  >
                    Computer Science<span>&bull;</span>
                    <em className="date">2005</em>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Resume;
