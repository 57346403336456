import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"

var d = new Date();
var currentYear = d.getFullYear();

class Footer extends Component {

  render() {

    if(this.props.data){
      var networks= this.props.data.social.map(function(network){
        if (network.name !== 'mail') {
            return <li key={network.name}><a rel="noopener noreferrer"  target = "_blank" href={network.url}><FontAwesomeIcon icon={['fab', network.name]} /></a></li>
        } else {
            return <li key={network.name}><a rel="noopener noreferrer"  target = "_blank" href={network.url}><FontAwesomeIcon icon={faEnvelope} /></a></li>
        }
      })
    }

    return (
      <footer>

     <div className="row">
        <div className="twelve columns">
           <ul className="social-links">
              {networks}
           </ul>

           <ul className="copyright">
              <li>{currentYear} Al Elizalde</li>
           </ul>

        </div>
        <div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i className="icon-up-open"></i></a></div>
     </div>
  </footer>
    );
  }
}

export default Footer;
